import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbDatepicker, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PdfService } from 'src/services/pdf.service';
import { MovdService } from '../services/movd.service';
import { MoveSurvey } from '../models/move-survery.model';
import { AppComponent } from 'src/app/app.component';
import { CompanySetting, Currency, CurrencyOptions, getCurrencySymbol, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../models/companysetting.model';
import { LeadQuotation } from '../models/lead-quotation.model';
import { eMoveCategoryType, eTransportType, MoveCategoryTypeOptions, MoveTransportModal, MoveTypeOptions } from '../models/lead-detail.model';
import { GeneralService } from 'src/services/general.service';
import { AuthService } from 'src/services/auth.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { HttpClient } from '@angular/common/http';
import { EnumMappingService } from '../services/movd.enum.service';
import { SurveyMaterial } from '../models/survery-material.model';
import { EmailTemplate } from '../models/email-template.model';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { QuillModules } from 'ngx-quill';
import { eEmailType } from '../../auth/auth.models';
import { LeadBoardDTO, LeadBoardResponse } from '../lead-board/lead-board.model';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-lead-quotation',
  templateUrl: './lead-quotation.component.html',
  styleUrls: ['./lead-quotation.component.scss']
})
export class LeadQuotationComponent {
  // @Output() pdfDoc = new EventEmitter();
  @Input() leadDetailId: any;
  @Input() leadId: any;
  @Input() disabled: any;
  @Input() clientName: any;
  @Input() clientsCompany: any;
  @ViewChild('generateEmailModal') generateEmailModal!: ElementRef;
  @ViewChild('templateModal') templateModal: any;
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  @ViewChild('pdfComponent') pdfContent!: any;
  _moveSurvey: MoveSurvey = new MoveSurvey();
  _basicModalRef: NgbModalRef | undefined;
  @ViewChild('basicModal') basicModal: any;
  editModalRef: NgbModalRef | undefined;
  _currency: any;
  _currencies = CurrencyOptions;
  _selectedCurrency: Currency = Currency.USD;
  leadQuotation: LeadQuotation = new LeadQuotation();
  saved: boolean = false;


  _companySetting = new CompanySetting();
  _leadQuotation = new LeadQuotation();
  _moveTransport = new MoveTransportModal();
  _title = "Generate Email";
  _temp = new EmailTemplate();
  _tempTitle!: string | null;
  _tempSubject!: string | null;
  _tempBody!: string | null;
  tempExists: boolean = false;
  templateType = "Quotation Template";
  _quotationDate: NgbDate | null = null;
  _validationDate: NgbDate | null = null;
  template1Checked: boolean = false;
  type: string = '';
  method: string = '';
  fromPoe: string = '';
  toResidence: string = '';
  estWeight: string = '';
  charges: string = '';
  inclusions: string = '';
  exclusions: string = '';
  previewData: any;
  _tempTableData: EmailTemplate[] = [];
  tempTableData: any[] = [];
  _tempDataList: any[] = [];
  _tempTableColumns: any[] = [];
  _searchTempItems: string = '';
  eMoveCategoryType = eMoveCategoryType;
  eTransportType = eTransportType;
  private modalRef: NgbModalRef | undefined;
  pdfData: Blob = new Blob([], { type: 'application/pdf' });
  _pdfContent: any;
  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;
  _userName: string | null = null;
  _customerId: number | null = null;
  _companyId: number | null = null;
  _currentUserId: number | null = null;

  // for pdfMake document
  pdfDocGenerator: any;
  _leadDetail = new LeadBoardDTO();
  _surveyMaterialsList!: SurveyMaterial[];
  _areaItems: SurveyMaterial[] = [];
  _materials: SurveyMaterial[] = [];
  _labour: SurveyMaterial[] = [];
  _vehicles: SurveyMaterial[] = [];
  _totalWeight = 0;
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _moveCategory = MoveCategoryTypeOptions;
  _moveType = MoveTypeOptions;
  _dictionary: { [key: string]: any } = {}
  eEmailType = eEmailType;

  constructor(private modalService: NgbModal,
    private _pdfService: PdfService,
    private _movdService: MovdService,
    public generalService: GeneralService,
    private _appComponent: AppComponent,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _http: HttpClient,
    public enumMappingService: EnumMappingService,
  ) {
    this._userRole = localStorage.getItem('role');
    this._companyId = Number(localStorage.getItem('companyId'));
    this._currentUserId = Number(localStorage.getItem('currentUserId'));

  }

  async ngOnInit() {
    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Lead Info Form - Quotation.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Lead Info Form - Quotation.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('Lead Info Form - Quotation.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Lead Info Form - Quotation.Delete');


  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['leadDetailId']) {
      this.leadDetailId = changes['leadDetailId'].currentValue;
      if (this.leadDetailId) {
        this._areaItems = [];
        this._materials = [];
        this._labour = [];
        this._vehicles = [];
        this._totalWeight = 0;

        this.getMoveSurveys();
        this.getCompanySetting();
        this.getMoveTransportByLeadDetailId();
        this.getLeadDetailsByLeadDetailId();
        this.getLeadQuotation();
      }
    } else if (changes['disabled']) {
      this.disabled = changes['disabled'].currentValue;
      // console.log("disabled", changes['disabled']);
    }

    if (changes['leadId']) {
      this.leadId = changes['leadId'].currentValue;
      if (this.leadId) {
        // this.getLeadInfo();
      }
    }
  }

  getMoveTransportByLeadDetailId() {
    this._movdService.getMoveTransportByLeadDetailId(this.leadDetailId).subscribe({
      next: (response: any) => {
        // console.log("get transport:", response);
        if (response) {
          this._moveTransport = response;
          // console.log('move transport', this._moveTransport);
        } else {
          this._moveTransport = new MoveTransportModal();
        }
      },
      error: (error: any) => {
        console.error("error in getMoveTransportByLeadDetailId", error);
      }
    });
  }

  getLeadQuotation() {
    this._movdService.getLeadQuotationsByLeadDetailId(this.leadDetailId).subscribe({
      next: (response: any) => {
        console.log("get quotation:", response);
        if (response) {
          this._leadQuotation = response;
          this.saved = true;
          console.log("Assigned LeadQuotation:", this._leadQuotation);
          // this.createPdf();
          // this.savePdf();
          this.createDictionary();
          if (response.currency) {
            this._selectedCurrency = response.currency;
            this._currency = getCurrencySymbol(response.currency);
          }
          else {
            return;
          }
          this.onChange();
        } else {
          this._leadQuotation = new LeadQuotation();
          this._leadQuotation.leadDetailId = this.leadDetailId;
          //console.log('lead quotation detal id', this._leadQuotation.leadDetailId)
        }
        // this.createPdf();  // every time lead summary will be retrieved from the db the create pdf function will be called to generate pdf of the current summary 
      },
      error: (error: any) => {
        this._leadQuotation = new LeadQuotation();
        this._leadQuotation.leadDetailId = this.leadDetailId;
        console.error("error getLeadQuotationsByLeadDetailId", error);
      }
    });
  }

  getLeadDetailsByLeadDetailId() {
    this._movdService.getLeadSummary(this.leadDetailId).subscribe({
      next: (response: LeadBoardResponse) => {
        if (response && response.leads && response.leads.length > 0) {
          this._leadDetail = response.leads[0];
          // console.log("move summary", this._moveSummay);
          // this.createPdf();
        }
        // console.log("getMoveSummary", response);
      },
      error: (error: any) => {
        console.error("error getting lead detail by leadDetailId", error);
      }
    });
  }

  onDateSelect(date: NgbDate, type: string) {
    const dateToSet = this.convertNgbDateToDate(date);

    if (type === 'Quotation') {
      this._leadQuotation.date = dateToSet;
    } else if (type === 'Validation') {
      this._leadQuotation.validationDate = dateToSet;
    }
    this.onChange();
  }
  onChange() {
    this._quotationDate = this.convertDateToNgbDate(this.generalService.formatDate(this._leadQuotation.date, null));
    this._validationDate = this.convertDateToNgbDate(this.generalService.formatDate(this._leadQuotation.validationDate, null));
  }

  convertNgbDateToDate(ngbDate: any): Date | null {
    if (ngbDate !== null) {
      const utcDate = new Date(Date.UTC(
        ngbDate.year,
        ngbDate.month - 1,
        ngbDate.day
      ));
      return utcDate;
    } else {
      return null;
    }
  }
  isHighlighted(date: NgbDateStruct, dateField: string) {
    let dateToCompare: NgbDate | null;

    if (dateField === 'quotation') {
      dateToCompare = this.convertDateToNgbDate(this._leadQuotation.date);
    } else if (dateField === 'validation') {
      dateToCompare = this.convertDateToNgbDate(this._leadQuotation.validationDate);
    } else {
      return false; // Invalid dateField
    }

    if (dateToCompare) {
      return (
        date.year === dateToCompare.year &&
        date.month === dateToCompare.month &&
        date.day === dateToCompare.day
      );
    }

    return false;
  }
  convertDateToNgbDate(date: string | Date | null): NgbDate | null {
    if (date) {
      let jsDate = new Date(date);
      return new NgbDate(
        jsDate.getFullYear(),
        jsDate.getMonth() + 1,   // NgbDate month is 1-based
        jsDate.getDate() //+ 1
      );
    } else {
      return null;
    }
  }

  openTempModal(): void {

    if (this.generateEmailModal) {
      this.modalRef = this.modalService.open(this.generateEmailModal, { centered: true, backdrop: 'static' });
    }
  }


  openModal() {
    const modalRef = this.modalService.open(this.templateModal, { centered: true });
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
  exportData() {

  }
  sendDataToPreview() {
    this.previewData = {
      type: this.type,
      method: this.method,
      fromPoe: this.fromPoe,
      toResidence: this.toResidence,
      estWeight: this.estWeight,
      charges: this.charges,
      inclusions: this.inclusions,
      exclusions: this.exclusions
    };
    this.modalService.dismissAll();
  }
  openEditModal(content: any) {
    this['editModalRef'] = this.modalService.open(content);
  }
  saveChanges(modal: NgbModalRef) {
    // Perform your saving logic here, e.g., sending data to a backend API.
    // After successful saving, close the modal.
    // For demonstration purposes, we'll just close the modal here.
    modal.close('Save click');
  }
  generatePDF() {
    const contentElement = this.pdfContent.nativeElement;

    if (contentElement) {
      contentElement.style.visibility = 'visible';
      contentElement.offsetHeight;

      // Generate the PDF
      this._pdfService.ConvertHTMLToPDF(contentElement, 'Move-Quotation-' + this.leadDetailId);
      // Revert the visibility back to hidden
      contentElement.style.visibility = 'hidden';
    } else {
      console.error('HTML content not found');
    }
  }


  openPdfModel() {
    const modalOptions: NgbModalOptions = {
      size: 'lg',       // Set the size of the modal (lg = large)
      centered: true,   // Center the modal vertically and horizontally
    };

    // Open the modal with the specified options
    this._basicModalRef = this._modalService.open(this.basicModal, modalOptions);
  }

  getMoveSurveys() {
    this._movdService.getMoveSurvey(this.leadDetailId).subscribe({
      next: (data: any) => {
        this._moveSurvey = new MoveSurvey();
        if (data) {
          this._moveSurvey = data;
          this._surveyMaterialsList = this._moveSurvey.surveyMaterials;

          this._surveyMaterialsList.forEach((material: SurveyMaterial) => {
            switch (material.type) {
              case 'Material':
                this._materials.push(material);
                break;
              case 'Area Item':
                this._areaItems.push(material);
                if (material.weight) {
                  this._totalWeight += material.weight;
                }
                break;
              case 'Labour':
                this._labour.push(material);
                break;
              case 'Vehicle':
                this._vehicles.push(material);
                break;
              // Handle other types if needed
              default:
                break;
            }
          });
        }
        // console.log("move survey list", data);
      },
      error: (error: any) => {
        console.error(error);
      }
    });
  }

  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        console.log('getting company settings:', response);
        this._companySetting = response;
        if (response.weightUnit) {
          this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
        }
        if (response.volumeUnit) {
          this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
        }
        if (response.currency) {
          this._selectedCurrency = response.currency;
          // console.log("selected curr", this._selectedCurrency);
          this._currency = getCurrencySymbol(response.currency);
        }

        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }

  saveLeadQuotation() {
    this._leadQuotation.leadDetailId = this.leadDetailId;
    this._leadQuotation.currency = this._selectedCurrency;
    this._movdService.saveLeadQuotation(this._leadQuotation).subscribe({
      next: (response: any) => {
        // console.log("save lead quotation response", response);
        this.saved = true;
        this._appComponent.showSuccessSwal("created", "record");
        this.getLeadQuotation();
        // this.reloadSurveyPrintPreview();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("creating", "record");
        console.error("Error saving lead quotation:", error);
      }
    });
  }

  saveMoveSurvey() {

    // console.log("selected move detail id ", this.leadDetailId);

    // console.log("save _moveSurvey", this._moveSurvey);

    this._movdService.saveMoveSurvey(this._moveSurvey).subscribe({
      next: (response: any) => {
        // console.log("save _moveSurvey respnse", response);
        this._appComponent.showSuccessSwal("created", "record");
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("creating", "record");
        console.error("error", error);
      }
    });

  }
  reloadSurveyPrintPreview() {
    // console.log('pdfComponent:', this.pdfContent);
    if (this.pdfContent) {
      this.pdfContent.reload();
    }
  }

  formatDate(date: string | Date | null): string | null {
    const isValidDate = (d: any) => d && !isNaN(Date.parse(d));

    if (isValidDate(date)) {
      const formatDate = (d: Date) => {
        const day = d.getDate().toString().padStart(2, '0');
        const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        const year = d.getFullYear().toString().slice(-2); // Get the last two digits of the year
        return `${day}-${month}-${year}`;
      };

      return formatDate(new Date(date as string));
    }

    return null;
  }

  createPdf() {
    console.log("company setting in create pdf", this._companySetting);

    return new Promise<Blob>((resolve, reject) => {
      // Include your docDefinition code here
      var docDefinition: any = {
        content: [
          this._companySetting.logo && this._companySetting.logo.startsWith('data:image') ? {
            image: this._companySetting.logo,
            width: 110,
            alignment: 'center',
          } : {
            text: 'Company Logo',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10],
          },
          {
            text: 'Quotation',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10] // [left, top, right, bottom]
          },
          {
            table: {
              layout: 'lightHorizontalLines',
              // layout: {
              //   hLineWidth: function (i: any, node: any) {
              //     return (i === 0 || i === node.table.body.length) ? 2 : 1;
              //   },
              //   vLineWidth: function (i: any, node: any) {
              //     return (i === 0 || i === node.table.widths.length) ? 2 : 1;
              //   },
              // },
              headerRows: 1,
              widths: ['33%', '33%', '34%'],
              body: [
                [
                  { text: 'From', style: 'tableHeader', alignment: 'center' },
                  { text: 'To', style: 'tableHeader', alignment: 'center' },
                  { text: 'Quotation Details', style: 'tableHeader', alignment: 'center' }
                ],
                // Dynamic data goes here
              ],
            },
            margin: [0, 10, 0, 10] // [left, top, right, bottom]
          },
          {
            table: {
              layout: 'lightHorizontalLines',
              widths: ['50%', '50%'], // Two columns
              body: [
              ]
            },
            margin: [0, 10, 0, 20] // [left, top, right, bottom]
          }
        ],
        styles: {
          header: {
            fontSize: 20,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          columnText: {
            fontSize: 11,
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          smallHeadings: {
            bold: true,
            fontSize: 14,
            color: 'black'
          }
        },
        footer: function (currentPage: any, pageCount: any) {
          return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 10, alignment: 'right', margin: [0, 0, 35, 0] };
        }
      };

      // Your dynamic data
      var fromData = [
        { text: this._companySetting.companyName ? this._companySetting.companyName : '-', style: 'columnText', alignment: 'left' },
        {
          text: this._companySetting.streetAddress ? (this._companySetting.streetAddress + ', ') : '' +
            this._companySetting.city ? (this._companySetting.city + ', ') : '' +
              this._companySetting.country ? this._companySetting.country : '-',
          style: 'columnText', alignment: 'left'
        },
        { text: 'Email: ' + (this._companySetting.email ? this._companySetting.email : '-'), style: 'columnText', alignment: 'left' },
        { text: 'Contact: ' + (this._companySetting.contact ? this._companySetting.contact : '-'), style: 'columnText', alignment: 'left' }
      ];


      // console.log('fromData:', fromData);

      var toData = [
        { text: this._leadDetail.customer || '', style: 'columnText', alignment: 'left' },
        { text: 'Email: ' + this._leadDetail.email || '', style: 'columnText', alignment: 'left' },
      ];

      var quotationDetailsData = [
        { text: 'Date: ' + (this._leadQuotation && this._leadQuotation.date ? this.generalService.formatDate(this._leadQuotation.date, null) : '-'), style: 'columnText', alignment: 'left' },
        { text: 'Quotation #: ' + (this._leadQuotation && this._leadQuotation.id ? this._leadQuotation.id : ' - '), style: 'columnText', alignment: 'left' },
        {
          text: 'Lead: ' + (this._leadQuotation && this._leadQuotation.leadDetailId
            ? this._leadDetail.formattedLeadDetailId
            : ' - '),
          style: 'columnText',
          alignment: 'left'
        },

        { text: 'Valid Till: ' + (this._leadQuotation && this._leadQuotation.validationDate ? this.generalService.formatDate(this._leadQuotation.validationDate, null) : '-'), style: 'columnText', alignment: 'left' },
        {
          text: 'Customer: ' + (this._leadDetail && this._leadDetail.customerId
            ? this._leadDetail.formattedCustomerId
            : ' - '),
          style: 'columnText',
          alignment: 'left'
        }

      ];


      var maxLines = Math.max(fromData.length, toData.length, quotationDetailsData.length);

      if (docDefinition && docDefinition.content && docDefinition.content[2] && docDefinition.content[2].table && docDefinition.content[2].table.body) {
        // Add dynamic data to the table
        for (var i = 0; i < maxLines; i++) {
          var fromLine = fromData[i] || { text: '', style: 'columnText', alignment: 'left' };
          var toLine = toData[i] || { text: '', style: 'columnText', alignment: 'left' };
          var quotationDetailsLine = quotationDetailsData[i] || { text: '', style: 'columnText', alignment: 'left' };

          docDefinition.content[2].table.body.push([fromLine, toLine, quotationDetailsLine]);
          // console.log("for loop");
        }
      }

      // Your dynamic data for the second table
      var secondTableData = [];
      const currencyValue = this._currency ? this._currency : '-';
      // Check conditions and add data accordingly
      secondTableData.push(
        [{ text: 'Category:', style: 'columnText', alignment: 'left' },
        { text: this._leadDetail.moveCategoryLabel || ' - ', style: 'columnText', alignment: 'left' }]
      );

      secondTableData.push(
        [{ text: 'Type:', style: 'columnText', alignment: 'left' },
        { text: this._leadDetail.moveTypeLabel || ' - ', style: 'columnText', alignment: 'left' }]
      );

      if ((this._moveTransport.categoryType == eMoveCategoryType.PortToDoor && (this._moveTransport.transportType == eTransportType.Sea || this._moveTransport.transportType == eTransportType.SeaLCL || this._moveTransport.transportType == eTransportType.Air))) {
        secondTableData.push(
          [{ text: 'From POL', style: 'columnText', alignment: 'left' },
          { text: this._leadDetail.portOfLoading || ' - ', style: 'columnText', alignment: 'left' }]
        );

        secondTableData.push(
          [{ text: 'To Residence:', style: 'columnText', alignment: 'left' },
          { text: this._leadDetail.destination || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      if ((this._moveTransport.categoryType == eMoveCategoryType.DoorToPort && (this._moveTransport.transportType == eTransportType.Sea || this._moveTransport.transportType == eTransportType.SeaLCL || this._moveTransport.transportType == eTransportType.Air))) {
        secondTableData.push(
          [{ text: 'From Residence', style: 'columnText', alignment: 'left' },
          { text: this._leadDetail.origin || ' - ', style: 'columnText', alignment: 'left' }]
        );

        secondTableData.push(
          [{ text: 'To POD:', style: 'columnText', alignment: 'left' },
          { text: this._leadDetail.portOfDischarge || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }

      if (!((this._moveTransport.categoryType == eMoveCategoryType.DoorToPort && (this._moveTransport.transportType == eTransportType.Sea || this._moveTransport.transportType == eTransportType.SeaLCL || this._moveTransport.transportType == eTransportType.Air)) ||
        (this._moveTransport.categoryType == eMoveCategoryType.PortToDoor && (this._moveTransport.transportType == eTransportType.Sea || this._moveTransport.transportType == eTransportType.SeaLCL || this._moveTransport.transportType == eTransportType.Air)))) {
        secondTableData.push(
          [{ text: 'From Origin:', style: 'columnText', alignment: 'left' },
          { text: this._leadDetail.origin || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      if (!((this._moveTransport.categoryType == eMoveCategoryType.DoorToPort && (this._moveTransport.transportType == eTransportType.Sea || this._moveTransport.transportType == eTransportType.SeaLCL || this._moveTransport.transportType == eTransportType.Air)) ||
        (this._moveTransport.categoryType == eMoveCategoryType.PortToDoor && (this._moveTransport.transportType == eTransportType.Sea || this._moveTransport.transportType == eTransportType.SeaLCL || this._moveTransport.transportType == eTransportType.Air)))) {
        secondTableData.push(
          [{ text: 'To Destination:', style: 'columnText', alignment: 'left' },
          { text: this._leadDetail.destination || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }

      secondTableData.push(
        [{ text: 'Est. Weight/Volume:', style: 'columnText', alignment: 'left' },
        { text: this.calculateTotal().toFixed(2) + ' ' + this._weightUnit + ' / ' + this.calculateTotal().toFixed(2) + ' ' + this._volumeUnit, style: 'columnText', alignment: 'left' }]
      );

      if (!((this._moveTransport.categoryType == eMoveCategoryType.PortToDoor && this._moveTransport.transportType == eTransportType.Sea) ||
        (this._moveTransport.categoryType == eMoveCategoryType.PortToDoor && this._moveTransport.transportType == eTransportType.Air))) {
        secondTableData.push(
          [{ text: 'Origin Charges:', style: 'columnText', alignment: 'left' },
          { text: this._leadQuotation.originCharges ? currencyValue + ' ' + this._leadQuotation.originCharges : currencyValue + ' 0', style: 'columnText', alignment: 'left' }]
        );
      }

      if (!((this._moveTransport.categoryType == eMoveCategoryType.PortToDoor && this._moveTransport.transportType == eTransportType.Sea) ||
        (this._moveTransport.categoryType == eMoveCategoryType.PortToDoor && this._moveTransport.transportType == eTransportType.Air))) {
        secondTableData.push(
          [{ text: 'Freight Charges:', style: 'columnText', alignment: 'left' },
          { text: this._leadQuotation.freightCharges ? currencyValue + ' ' + this._leadQuotation.freightCharges : currencyValue + ' 0', style: 'columnText', alignment: 'left' }]
        );
      }

      if (!((this._moveTransport.categoryType == this.eMoveCategoryType.DoorToPort && this._moveTransport.transportType == this.eTransportType.SeaLCL) ||
        (this._moveTransport.categoryType == this.eMoveCategoryType.DoorToPort && this._moveTransport.transportType == this.eTransportType.Sea) ||
        (this._moveTransport.categoryType == this.eMoveCategoryType.DoorToPort && this._moveTransport.transportType == this.eTransportType.Air) ||
        (this._moveTransport.categoryType == this.eMoveCategoryType.PortToDoor && this._moveTransport.transportType == this.eTransportType.Sea) ||
        (this._moveTransport.categoryType == this.eMoveCategoryType.PortToDoor && this._moveTransport.transportType == this.eTransportType.Air))) {
        secondTableData.push(
          [{ text: 'Destination Charges:', style: 'columnText', alignment: 'left' },
          { text: this._leadQuotation.destinationCharges ? currencyValue + ' ' + this._leadQuotation.destinationCharges : currencyValue + ' 0', style: 'columnText', alignment: 'left' }]
        );
      }

      if (this._leadQuotation.originCharges && this._leadQuotation.freightCharges && this._leadQuotation.destinationCharges) {
        secondTableData.push(
          [{ text: 'Total:', style: 'columnText', alignment: 'left' },
          { text: currencyValue + ' ' + (this._leadQuotation.originCharges + this._leadQuotation.freightCharges + this._leadQuotation.destinationCharges), style: 'columnText', alignment: 'left' }]
        );
      } else {
        secondTableData.push(
          [{ text: 'Total:', style: 'columnText', alignment: 'left' },
          { text: currencyValue + ' 0', style: 'columnText', alignment: 'left' }]
        );
      }

      if (docDefinition && docDefinition.content && docDefinition.content[3] && docDefinition.content[3].table && docDefinition.content[3].table.body) {
        // Add dynamic data to the second table
        secondTableData.forEach(data => {
          docDefinition.content[3].table.body.push(data);
        });
      }

      // console.log('second table data', secondTableData);

      if (this._leadQuotation.destinationCustomClearance || this._leadQuotation.inlandHaulage || this._leadQuotation.delivery || this._leadQuotation.returnEmptyUnit ||
        this._leadQuotation.packAndLoad || this._leadQuotation.dockTransfer || this._leadQuotation.exportClearance || this._leadQuotation.transitTime ||
        this._leadQuotation.packOut || this._leadQuotation.airPortTransfer || this._leadQuotation.importClearance) {
        docDefinition.content.push(
          { text: 'Estimated Services Schedule:', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] }
        );

        if (this._moveTransport.categoryType == this.eMoveCategoryType.DoorToPort && this._moveTransport.transportType == this.eTransportType.Sea) {
          if (this._leadQuotation.packAndLoad) {
            docDefinition.content.push({ text: 'Pack and Load: ' + this._leadQuotation.packAndLoad, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.dockTransfer) {
            docDefinition.content.push({ text: 'Dock Transfer: ' + this._leadQuotation.dockTransfer, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.exportClearance) {
            docDefinition.content.push({ text: 'Export Clearance: ' + this._leadQuotation.exportClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.transitTime) {
            docDefinition.content.push({ text: 'Transit Time: ' + this._leadQuotation.transitTime, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
        }

        if (this._moveTransport.categoryType == this.eMoveCategoryType.DoorToPort && this._moveTransport.transportType == this.eTransportType.Air) {
          if (this._leadQuotation.exportClearance) {
            docDefinition.content.push({ text: 'Export Clearance: ' + this._leadQuotation.exportClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.transitTime) {
            docDefinition.content.push({ text: 'Transit Time: ' + this._leadQuotation.transitTime, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.packOut) {
            docDefinition.content.push({ text: 'Pack Out: ' + this._leadQuotation.packOut, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.airPortTransfer) {
            docDefinition.content.push({ text: 'AirPort Transfer: ' + this._leadQuotation.airPortTransfer, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
        }

        if (this._moveTransport.categoryType == this.eMoveCategoryType.PortToDoor && this._moveTransport.transportType == this.eTransportType.Air) {
          if (this._leadQuotation.destinationCustomClearance) {
            docDefinition.content.push({ text: 'Destination Custom Clearance: ' + this._leadQuotation.destinationCustomClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.delivery) {
            docDefinition.content.push({ text: 'Delivery: ' + this._leadQuotation.delivery, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
        }

        if (this._moveTransport.categoryType == this.eMoveCategoryType.PortToDoor && this._moveTransport.transportType == this.eTransportType.Sea) {
          if (this._leadQuotation.destinationCustomClearance) {
            docDefinition.content.push({ text: 'Destination Custom Clearance: ' + this._leadQuotation.destinationCustomClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.delivery) {
            docDefinition.content.push({ text: 'Delivery: ' + this._leadQuotation.delivery, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.returnEmptyUnit) {
            docDefinition.content.push({ text: 'Return of empty unit: ' + this._leadQuotation.returnEmptyUnit, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.inlandHaulage) {
            docDefinition.content.push({ text: 'Inland Haulage: ' + this._leadQuotation.inlandHaulage, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
        }

        if ((this._moveTransport.categoryType == this.eMoveCategoryType.DoorToDoorLocal || this._moveTransport.categoryType == this.eMoveCategoryType.DoorToDoorImport || this._moveTransport.categoryType == this.eMoveCategoryType.DoorToDoorExport) && this._moveTransport.transportType == this.eTransportType.Air) {
          if (this._leadQuotation.packAndLoad) {
            docDefinition.content.push({ text: 'Pack and Load: ' + this._leadQuotation.packAndLoad, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.dockTransfer) {
            docDefinition.content.push({ text: 'Dock Transfer: ' + this._leadQuotation.dockTransfer, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.exportClearance) {
            docDefinition.content.push({ text: 'Export Clearance: ' + this._leadQuotation.exportClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.transitTime) {
            docDefinition.content.push({ text: 'Transit Time: ' + this._leadQuotation.transitTime, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.delivery) {
            docDefinition.content.push({ text: 'Delivery: ' + this._leadQuotation.delivery, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.returnEmptyUnit) {
            docDefinition.content.push({ text: 'Return of empty unit: ' + this._leadQuotation.returnEmptyUnit, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.importClearance) {
            docDefinition.content.push({ text: 'Import Clearance: ' + this._leadQuotation.importClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.packOut) {
            docDefinition.content.push({ text: 'Pack Out: ' + this._leadQuotation.packOut, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.airPortTransfer) {
            docDefinition.content.push({ text: 'AirPort Transfer: ' + this._leadQuotation.airPortTransfer, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
        }

        if ((this._moveTransport.categoryType == this.eMoveCategoryType.DoorToDoorLocal || this._moveTransport.categoryType == this.eMoveCategoryType.DoorToDoorImport || this._moveTransport.categoryType == this.eMoveCategoryType.DoorToDoorExport) && this._moveTransport.transportType == this.eTransportType.Sea) {
          if (this._leadQuotation.packAndLoad) {
            docDefinition.content.push({ text: 'Pack and Load: ' + this._leadQuotation.packAndLoad, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.dockTransfer) {
            docDefinition.content.push({ text: 'Dock Transfer: ' + this._leadQuotation.dockTransfer, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.exportClearance) {
            docDefinition.content.push({ text: 'Export Clearance: ' + this._leadQuotation.exportClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.transitTime) {
            docDefinition.content.push({ text: 'Transit Time: ' + this._leadQuotation.transitTime, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.delivery) {
            docDefinition.content.push({ text: 'Delivery: ' + this._leadQuotation.delivery, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.returnEmptyUnit) {
            docDefinition.content.push({ text: 'Return of empty unit: ' + this._leadQuotation.returnEmptyUnit, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.importClearance) {
            docDefinition.content.push({ text: 'Import Clearance: ' + this._leadQuotation.importClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
        }

        // For the condition
        if ((this._moveTransport.categoryType == this.eMoveCategoryType.DoorToDoorLocal || this._moveTransport.categoryType == this.eMoveCategoryType.DoorToDoorImport || this._moveTransport.categoryType == this.eMoveCategoryType.DoorToDoorExport) && this._moveTransport.transportType == this.eTransportType.SeaLCL) {
          if (this._leadQuotation.packAndLoad) {
            docDefinition.content.push({ text: 'Pack and Load: ' + this._leadQuotation.packAndLoad, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.dockTransfer) {
            docDefinition.content.push({ text: 'Dock Transfer: ' + this._leadQuotation.dockTransfer, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.exportClearance) {
            docDefinition.content.push({ text: 'Export Clearance: ' + this._leadQuotation.exportClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.transitTime) {
            docDefinition.content.push({ text: 'Transit Time: ' + this._leadQuotation.transitTime, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.delivery) {
            docDefinition.content.push({ text: 'Delivery: ' + this._leadQuotation.delivery, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.commodity) {
            docDefinition.content.push({ text: 'Commodity: ' + this._leadQuotation.commodity, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.destinationCustomClearance) {
            docDefinition.content.push({ text: 'Destination Custom Clearance: ' + this._leadQuotation.destinationCustomClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
        }

        if (this._moveTransport.categoryType == this.eMoveCategoryType.DoorToPort && this._moveTransport.transportType == this.eTransportType.SeaLCL) {
          if (this._leadQuotation.packAndLoad) {
            docDefinition.content.push({ text: 'Pack and Load: ' + this._leadQuotation.packAndLoad, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.dockTransfer) {
            docDefinition.content.push({ text: 'Dock Transfer: ' + this._leadQuotation.dockTransfer, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.exportClearance) {
            docDefinition.content.push({ text: 'Export Clearance: ' + this._leadQuotation.exportClearance, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.transitTime) {
            docDefinition.content.push({ text: 'Transit Time: ' + this._leadQuotation.transitTime, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
          if (this._leadQuotation.commodity) {
            docDefinition.content.push({ text: 'Commodity: ' + this._leadQuotation.commodity, style: 'columnText', alignment: 'left', margin: [0, 0, 0, 5] });
          }
        }

      }

      // Accessorial Rates
      docDefinition.content.push(
        { text: 'Accessorial Rates:', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        { text: this._leadQuotation.accessorialRates || ' N/A ', style: 'columnText', alignment: 'left' }
      );

      // Inclusions
      docDefinition.content.push(
        { text: 'Inclusions*:', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        { text: this._leadQuotation.inclusions || ' N/A ', style: 'columnText', alignment: 'left' }
      );

      // Exclusions
      docDefinition.content.push(
        { text: 'Exclusions*:', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        { text: this._leadQuotation.exclusions || ' N/A ', style: 'columnText', alignment: 'left' }
      );

      // Insurance Liability
      docDefinition.content.push(
        { text: 'Insurance Liability:', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        { text: this._leadQuotation.insuranceLiabilities || ' N/A ', style: 'columnText', alignment: 'left' }
      );

      // Payment Terms
      docDefinition.content.push(
        { text: 'Payment Terms:', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        { text: this._leadQuotation.termsAndConditions || ' N/A ', style: 'columnText', alignment: 'left' }
      );

      docDefinition.content.push(
        { text: '\nQUOTATION ACCEPTANCE NOTE:', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        { text: 'I have read the above quotation and authorize ' + this._companySetting.companyName + ' to proceed.', style: 'columnText', alignment: 'center', margin: [0, 0, 0, 50] },
        {
          columns: [
            { text: 'Signature:____________________________________', style: 'columnText', alignment: 'left' },
            { text: 'Date:____________________________________', style: 'columnText', alignment: 'right' }
          ]
        }
      );

      // console.log('docDefinition:', docDefinition);

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((blob: Blob) =>
        this.pdfData = (blob),
        resolve(this.pdfData)
      );
      this.pdfDocGenerator = pdfDocGenerator;
    });
  }
  hasData(): boolean {
    return [
      this._leadQuotation.date,
      this._leadQuotation.validationDate,
      this._leadQuotation.originCharges,
      this._leadQuotation.freightCharges,
      this._leadQuotation.destinationCharges,
      this._leadQuotation.inclusions,
      this._leadQuotation.exclusions,
      this._leadQuotation.insuranceLiabilities,
      this._leadQuotation.accessorialRates,
      this._leadQuotation.termsAndConditions
    ].some(value => {
      const isValid = value != null && value !== '' && (typeof value === 'number' ? value !== 0 : true);
      console.log(`Value: ${value}, isValid: ${isValid}`);
      return isValid;
    });
  }




  async viewPdf() {

    if (!this.hasData()) {
      // Show error dialog if no data
      this._appComponent.showAlertMessageDialog('View PDF', 'Quotation data is missing. Please save the quotation first.');
      return;
    }
    if (!this.saved) {
      // Show error dialog if the quotation is not saved
      this._appComponent.showAlertMessageDialog('View PDF', 'Quotation is not saved. Please save the quotation first.');
      return;
    }


    if (!this.pdfDocGenerator) {
      await this.createPdf();
    }
    await this.pdfDocGenerator.open();
  }

  async downloadPdf() {

    if (!this.hasData()) {
      // Show error dialog if no data
      this._appComponent.showAlertMessageDialog('Export PDF', 'Quotation data is missing. Please save the quotation first.');
      return;
    }
    if (!this.saved) {
      // Show error dialog if the quotation is not saved
      this._appComponent.showAlertMessageDialog('Export PDF', 'Quotation is not saved. Please save the quotation first.');
      return;
    }

    if (!this.pdfDocGenerator) {
      await this.createPdf();
    }
    await this.pdfDocGenerator.download();
  }

  calculateTotal(): number {
    let total = 0;
    for (const item of this._areaItems) {
      if (item.quantity && item.weight) {
        total += item.quantity * item.weight;
      }
    }
    return total;
  }
  closeModal(type: string, modal: any) {
    switch (type) {
      case 'Temp':
        modal.close();
        break;
      case 'TempItems':
        modal.close();
        break;
      default:
    }
  }
  // addNewTemplate(): void {

  // }

  createDictionary() {

    if (this._currentUserId !== undefined || null) {
      this._dictionary['User Id'] = this._currentUserId;
    }
    if (this.leadDetailId !== undefined || null) {
      this._dictionary['Lead ID'] = this._leadDetail.formattedLeadDetailId;
    }
    if (this.clientName !== undefined || null) {
      this._dictionary['Client Name'] = this.clientName;
    }
    if (this.clientName !== undefined || null) {
      this._dictionary['Quotation No'] = this._leadQuotation.id;
    }
    if (this.clientsCompany !== undefined || null) {
      this._dictionary['Client Company Name'] = this.clientsCompany;
    }
    else {
      this._dictionary['Client Company Name'] = '-';
    }
    // console.log('Merged Dictionary:', this._dictionary);
    return this._dictionary;
  }
  // async savePdf(): Promise<void> {
  //   try {
  //     var pdfBlob = await this.createPdf();
  //     this.pdfData = pdfBlob;
  //   } catch (error) {
  //     console.error('Error creating or saving PDF:', error);
  //   }
  // }
}