import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ConsignmentDetail, ConsignmentDetailItem } from '../../models/consignment-detail.model';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { MovdService } from '../../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import {
  CompanySetting,
  getCurrencySymbol,
  getVolumeUnitShortLabel,
  getWeightUnitShortLabel,
} from '../../models/companysetting.model';
import { HttpClient } from '@angular/common/http';
import {
  eMoveCategoryType,
  eTransportType,
} from '../../models/lead-detail.model';
import { MoveDetail } from '../../models/move-detail.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { eEmailType } from 'src/app/views/auth/auth.models';
import { GeneralService } from 'src/services/general.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Console } from 'console';

@Component({
  selector: 'app-consignment-details',
  templateUrl: './consignment-details.component.html',
  styleUrls: ['./consignment-details.component.scss'],
})
export class ConsignmentDetailsComponent implements OnInit {
  @Input() moveDetailId: any;
  @Input() moveCategory: any;
  @Input() moveTransport: any;
  @Input() clientName: any;
  @Input() clientsCompany: any;
  @Input() contentDisabled = false;
  @ViewChild('generateTemplateModal') generateTemplateModal!: ElementRef;

  pdfDocGenerator: any;
  _consignmentItemList: ConsignmentDetailItem[] = [];
  _consignmentDetail = new ConsignmentDetail();
  _consignmentDetailItem = new ConsignmentDetailItem();
  _companySetting = new CompanySetting();
  _portLabel: string | null = null;
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _templist: boolean = true;
  _title = 'Send Email';
  eEmailType = eEmailType;
  templateType = 'Consignment Email Template';
  _dictionary: { [key: string]: any } = {};
  _currentUserId: number | null = null;
  _currency: string = '';
  _shipperEmail: string | null = null;
  _trackingUrl: string | null = null;
  _moveDetailId: number = 0;
  _moveDetail = new MoveDetail();
  eMoveCategoryType = eMoveCategoryType;
  eMoveTransportType = eTransportType;
  _emailModalRef: NgbModalRef | undefined;
  pdfData: Blob = new Blob([], { type: 'application/pdf' });

  constructor(private _movdService: MovdService, private router: Router,
    private _modalService: NgbModal, public generalService: GeneralService,
    private _appComponent: AppComponent, private _http: HttpClient, private location: Location) {
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
  }

  ngOnInit(): void {
    this.getCompanySetting();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes['contentDisabled'] ||
      changes['moveDetailId'] ||
      changes['moveCategory'] ||
      changes['moveTransport']
    ) {
      if (changes['contentDisabled']) {
        this.contentDisabled = changes['contentDisabled'].currentValue;
      }
      if (changes['moveDetailId']) {
        this._moveDetailId = changes['moveDetailId'].currentValue;
        if (this._moveDetailId && this._moveDetailId !== 0) {
        }
      }
      if (changes['moveCategory']) {
        this.moveCategory = changes['moveCategory'].currentValue;
      }
      if (changes['moveTransport']) {
        this.moveTransport = changes['moveTransport'].currentValue;
      }
      await this.getMoveDetailById(this._moveDetailId);
      this.getConsignmentDetail();
      this.identifyPortLabel();
      await this.getCurrentUrl();
      this.createDictionary();
    }
  }
  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      },
    });
  }
  getConsignmentDetail() {
    this._movdService
      .getConsignmentByMoveDetailId(this._moveDetailId)
      .subscribe({
        next: (data: any) => {
          if (data) {
            //console.log('data consignment', data);
            this._consignmentDetail = data.consignmentDetail;
            if (
              this._consignmentDetail &&
              this._consignmentDetail.consignmentItems
            ) {
              this._consignmentItemList =
                this._consignmentDetail.consignmentItems;
            }
            if (data.recipientEmail) {
              // Corrected from data.RecipientEmail
              this._shipperEmail = data.recipientEmail; // Corrected from this._shipperEmail
            }
          }
          //console.log('consignment detail item', this._consignmentItemList);
          //console.log('recipients', this._shipperEmail);

          this.getMoveDetailById(this._moveDetailId);

          // console.log('consignment detail list', this._consignmentItemList);
          // this._appComponent.showSuccessSwal('Save', 'Consignment Info');
        },
        error: (error: any) => {
          // this._appComponent.showErrorSwal('Saving', 'record');
          // console.log("Error", error);
        },
      });
  }
  openTempModal(): void {
    if (this.generateTemplateModal) {
      this._emailModalRef = this._modalService.open(
        this.generateTemplateModal,
        { centered: true, backdrop: 'static' }
      );
    }
  }
  saveConsignment() {
    if (this._templist) {
      this._consignmentDetail.moveDetailId = this._moveDetailId;
      this._movdService.saveConsignment(this._consignmentDetail).subscribe({
        next: (data: any) => {
          this._appComponent.showSuccessSwal('Save', 'Consignment Info');
          this.getConsignmentDetail();
          this.createPdf();
        },
        error: (error: any) => {
          this._appComponent.showErrorSwal('Saving', 'record');
          // console.log("Error", error);
        },
      });
    } else {
      if (
        this._consignmentItemList.every((item) =>
          Object.values(item).some(
            (value) => value === null || value === undefined
          )
        )
      ) {
        this._appComponent.showErrorDialog(
          'Consignment Info',
          'Please save all items before saving the consignment.'
        );
        return;
      }
    }
  }
  saveConsignmentItem(rowIndex: number) {
    const item: ConsignmentDetailItem = this._consignmentItemList[rowIndex];
    item.consignmentDetailId = this._consignmentDetail.id;
    // console.log('Consignment Item', item);
    this._movdService.saveConsignmentItem(item).subscribe({
      next: (data: any) => {
        this._appComponent.showSuccessSwal('Save', 'Item');
        this._templist = true;
        // this.createPdf();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('Saving', 'record');
        // console.log("Error", error);
      },
    });
  }

  addConsignmentItem() {
    let newItem: ConsignmentDetailItem = {
      id: 0,
      consignmentDetailId: 0,
      marksAndNo: null,
      noOfPackages: null,
      description: null,
      netWeight: null,
      grossWeight: null,
      volume: null,
      isDeleted: false,
      commodityItemNo: null,
      chargeableWeight: null,
      rateOrCharge: null,
      total: null,
    };

    this._consignmentItemList.push(newItem);
    this._templist = false;
  }
  deleteConsignmentItem(rowIndex: number, itemList: any[]) {
    this._templist = true;
    if (itemList[rowIndex]) {
      const deletedItem = itemList.splice(rowIndex, 1)[0];
      if (deletedItem?.id) {
        deletedItem.isDeleted = true;
        this._movdService.saveConsignmentItem(deletedItem).subscribe({
          next: (data: any) => {
            this._appComponent.showSuccessSwal('Deleted', 'Item');
          },
          error: (error: any) => {
            this._appComponent.showErrorSwal('Deleting', 'record');
            // console.log("Error", error);
          },
        });
      }
    }
  }
  getMoveDetailById(moveDetailId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._movdService.getMoveDetailById(moveDetailId).subscribe({
        next: (data: any) => {
          if (data) {
            this._moveDetail = data;
            //console.log('move detail ', this._moveDetail);
            resolve(data);
          } else {
            reject('No data received');
          }
        },
        error: (error) => {
          console.error('error getting move detail by Id', error);
          reject(error);
        },
      });
    });
  }

  createPdf() {
    return new Promise<void>((resolve, reject) => {
      var docDefinition: any = {
        content: [
          this._companySetting.logo && this._companySetting.logo.startsWith('data:image') ? {
            image: this._companySetting.logo,
            width: 110,
            alignment: 'center',
          } : {
            text: 'Company Logo',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10],
          },
          {
            text: 'Shipping / OBL Instructions',
            style: 'header',
            alignment: 'left',
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          },
        ],
        styles: {
          header: {
            fontSize: 15,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          columnText: {
            fontSize: 10,
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
            color: 'black',
          },
          smallHeadings: {
            bold: true,
            fontSize: 12,
            color: 'black',
          },
          smallText: {
            italic: true,
            fontSize: 10,
            color: 'black',
          },
        },
        footer: function (currentPage: any, pageCount: any) {
          return {
            text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
            fontSize: 10,
            alignment: 'right',
            margin: [0, 5, 40, 0],
          };
        },
      };
      if (
        (this.moveCategory &&
          this.moveTransport &&
          this.moveCategory === eMoveCategoryType.DoorToDoorExport) ||
        this.moveCategory === eMoveCategoryType.DoorToPort ||
        this.moveCategory === eMoveCategoryType.DoorToDoorLocal
      ) {
      }
      if (
        this.moveTransport === this.eMoveTransportType.Sea ||
        this.moveTransport === this.eMoveTransportType.SeaLCL
      ) {
        docDefinition.content.push(
          {
            text: 'Bill of Lading / Booking No.',
            style: 'smallHeadings',
            alignment: 'right',
            margin: [0, 10, 0, 5],
          },
          {
            text: this._consignmentDetail.blNo,
            style: 'smallText',
            alignment: 'right',
            margin: [0, 0, 0, 8],
          }
        );
      }
      if (this.moveTransport === this.eMoveTransportType.Air) {
        docDefinition.content.push(
          {
            text: 'AirWay Bill No',
            style: 'smallHeadings',
            alignment: 'right',
            margin: [0, 10, 0, 5],
          },
          {
            text: this._consignmentDetail.awbNo,
            style: 'smallText',
            alignment: 'right',
            margin: [0, 0, 0, 8],
          }
        );
      }
      docDefinition.content.push({
        table: {
          layout: 'lightHorizontalLines',
          headerRows: 1,
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: 'SHIPPER INFO',
                style: 'tableHeader',
                alignment: 'center',
              },
              {
                text: 'CONSIGNEE INFO',
                style: 'tableHeader',
                alignment: 'center',
              },
            ],
            [
              {
                text: this._consignmentDetail.shipperInfo || '-',
                style: 'columnText',
                alignment: 'left',
              },
              {
                text: this._consignmentDetail.consigneeInfo || '-',
                style: 'columnText',
                alignment: 'left',
              },
            ],
          ],
        },
        margin: [0, 10, 0, 10], // [left, top, right, bottom]
      });
      docDefinition.content.push({
        table: {
          layout: 'lightHorizontalLines',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: 'Notify Address',
                style: 'tableHeader',
                alignment: 'center',
              },
            ],
            [
              {
                text: this._consignmentDetail.notifyInfo || '-',
                style: 'columnText',
                alignment: 'left',
              },
            ],
          ],
        },
        margin: [0, 10, 0, 10], // [left, top, right, bottom]
      });
      if (
        this.moveTransport === this.eMoveTransportType.Sea ||
        this.moveTransport === this.eMoveTransportType.SeaLCL
      ) {
        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['100%'],
            body: [
              [
                {
                  text: 'Forwarding Agent',
                  style: 'tableHeader',
                  alignment: 'center',
                },
              ],
              [
                {
                  text: this._consignmentDetail.forwardingAgent || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });
      }
      docDefinition.content.push({
        table: {
          layout: 'lightHorizontalLines',
          headerRows: 1,
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: 'Place of Receipt',
                style: 'tableHeader',
                alignment: 'center',
              },
              {
                text: 'Place of Delivery',
                style: 'tableHeader',
                alignment: 'center',
              },
            ],
            [
              {
                text: this._consignmentDetail.placeOfReceipt || '-',
                style: 'columnText',
                alignment: 'left',
              },
              {
                text: this._moveDetail.placeOfDelivery || '-',
                style: 'columnText',
                alignment: 'left',
              },
            ],
          ],
        },
        margin: [0, 10, 0, 10], // [left, top, right, bottom]
      });
      if (
        this.moveTransport === this.eMoveTransportType.Sea ||
        this.moveTransport === this.eMoveTransportType.SeaLCL
      ) {
        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['30%', '30%', '40%'],
            body: [
              [
                {
                  text: 'Vessel(s)',
                  style: 'tableHeader',
                  alignment: 'center',
                },
                {
                  text: 'Voyage No',
                  style: 'tableHeader',
                  alignment: 'center',
                },
                {
                  text: 'Carrier Reference',
                  style: 'tableHeader',
                  alignment: 'center',
                },
              ],
              [
                {
                  text: this._consignmentDetail.vessels || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
                {
                  text: this._consignmentDetail.voyageNo || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
                {
                  text: this._consignmentDetail.carrierReference || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });
      }
      if (
        this.moveTransport === this.eMoveTransportType.Sea ||
        this.moveTransport === this.eMoveTransportType.Air ||
        this.moveTransport === this.eMoveTransportType.SeaLCL
      ) {
        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [
                {
                  text: this._portLabel + ' Port of Loading',
                  style: 'tableHeader',
                  alignment: 'center',
                },
                {
                  text: this._portLabel + ' Port of Discharge',
                  style: 'tableHeader',
                  alignment: 'center',
                },
              ],
              [
                {
                  text: this._moveDetail.portOfLoading || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
                {
                  text: this._moveDetail.portOfDischarge || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });
      }
      if (
        this.moveTransport === this.eMoveTransportType.Sea ||
        this.moveTransport === this.eMoveTransportType.SeaLCL
      ) {
        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [
                {
                  text: 'Export Reference',
                  style: 'tableHeader',
                  alignment: 'center',
                },
                {
                  text: 'Consignee Reference',
                  style: 'tableHeader',
                  alignment: 'center',
                },
              ],
              [
                {
                  text: this._consignmentDetail.exportReference || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
                {
                  text:
                    this._consignmentDetail.consigneeReference || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });
      }
      if (
        this.moveTransport === this.eMoveTransportType.Air ||
        this.moveTransport === this.eMoveTransportType.Land
      ) {
        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['100%'],
            body: [
              [
                {
                  text: 'Issuing Carrier Info',
                  style: 'tableHeader',
                  alignment: 'center',
                },
              ],
              [
                {
                  text:
                    this._consignmentDetail.issuingCarrierDetail || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });
      }
      if (this.moveTransport === this.eMoveTransportType.Air) {
        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['100%'],
            body: [
              [
                {
                  text: 'Carrier IATA Code',
                  style: 'tableHeader',
                  alignment: 'center',
                },
              ],
              [
                {
                  text: this._consignmentDetail.carrierIATACode || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });
      }
      if (
        this.moveTransport === this.eMoveTransportType.Sea ||
        this.moveTransport === this.eMoveTransportType.Sea
      ) {
        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['100%'],
            body: [
              [
                {
                  text: 'Special Instructions',
                  style: 'tableHeader',
                  alignment: 'center',
                },
              ],
              [
                {
                  text:
                    this._consignmentDetail.specialInstruction || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });
      }
      if (
        this.moveTransport === this.eMoveTransportType.Sea ||
        this.moveTransport === this.eMoveTransportType.SeaLCL ||
        this.moveTransport === this.eMoveTransportType.Land
      ) {
        if (this._consignmentItemList.length > 0) {
          docDefinition.content.push({
            text: 'Consignment Items',
            style: 'smallHeadings',
            alignment: 'left',
            margin: [0, 10, 0, 10],
          });
          var consignmentItemListData = [];
          for (let row of this._consignmentItemList) {
            if (row.isDeleted === false) {
              consignmentItemListData.push([
                row.marksAndNo,
                row.noOfPackages,
                row.netWeight,
                row.grossWeight,
                row.volume,
                row.description,
              ]);
            }
          }

          docDefinition.content.push({
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['16%', '17%', '17%', '16%', '16%', '18%'],
              body: [
                [
                  {
                    text: 'Marks&No',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'No of Packages',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'Net Weight ' + '(' + this._weightUnit + ')',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text:
                      'Gross Weight ' + '(' + this._weightUnit + ')',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'Volume ' + '(' + this._volumeUnit + ')',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'Description',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                ],
                // Dynamic data goes here
                ...consignmentItemListData,
              ],
            },
            margin: [0, 10, 0, 10],
          });
        }
      }
      if (this.moveTransport === this.eMoveTransportType.Air) {
        if (this._consignmentItemList.length > 0) {
          docDefinition.content.push({
            text: 'Consignment Items',
            style: 'smallHeadings',
            alignment: 'left',
            margin: [0, 10, 0, 10],
          });

          const consignmentItemListData = [];
          for (const row of this._consignmentItemList) {
            if (!row.isDeleted) {
              consignmentItemListData.push([
                row.marksAndNo,
                row.noOfPackages,
                row.grossWeight,
                row.chargeableWeight,
                row.volume,
                row.commodityItemNo,
                row.rateOrCharge,
                row.total,
                row.description,
              ]);
            }
          }

          docDefinition.content.push({
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: [
                '10%',
                '10%',
                '10%',
                '10%',
                '10%',
                '10%',
                '10%',
                '10%',
                '20%',
              ],
              body: [
                [
                  {
                    text: 'Marks&No',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'No of Packages',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text:
                      'Gross Weight ' + '(' + this._weightUnit + ')',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text:
                      'Chargeable Weight ' +
                      '(' +
                      this._weightUnit +
                      ')',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'Volume ' + '(' + this._volumeUnit + ')',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'Commodity Item No',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'Rate / Charge ' + '(' + this._currency + ')',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'Total ' + '(' + this._currency + ')',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                  {
                    text: 'Description',
                    style: 'tableHeader',
                    alignment: 'left',
                  },
                ],
                // Dynamic data goes here
                ...consignmentItemListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          });
        }
      }

      if (
        this.moveTransport === this.eMoveTransportType.Sea ||
        this.moveTransport === this.eMoveTransportType.SeaLCL ||
        this.moveTransport === this.eMoveTransportType.Air
      ) {
        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [
                {
                  text: 'Freight Payment Type',
                  style: 'tableHeader',
                  alignment: 'center',
                },
                {
                  text: 'Specification of Freight Charges',
                  style: 'tableHeader',
                  alignment: 'center',
                },
              ],
              [
                {
                  text: this._consignmentDetail.freightPayment || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
                {
                  text:
                    this._consignmentDetail
                      .specificationOfFreightCharges || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });
      }
      if (this.moveTransport === this.eMoveTransportType.Land) {
        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [
                {
                  text: 'Received On',
                  style: 'tableHeader',
                  alignment: 'center',
                },
                {
                  text: 'Delivered On',
                  style: 'tableHeader',
                  alignment: 'center',
                },
              ],
              [
                {
                  text: this._consignmentDetail.receivedOn || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
                {
                  text: this._consignmentDetail.deliveredOn || '-',
                  style: 'columnText',
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });
      }
      // console.log('docDefinition:', docDefinition);

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      this.pdfDocGenerator = pdfDocGenerator;
      resolve();
    });
  }

  async viewPdf() {
    if (!this.pdfDocGenerator) {
      await this.createPdf();
    }
    this.pdfDocGenerator.open();
  }

  async downloadPdf() {
    if (!this.pdfDocGenerator) {
      await this.createPdf();
    }
    this.pdfDocGenerator.download();
  }
  identifyPortLabel() {
    if (
      (this.moveCategory &&
        this.moveTransport &&
        this.moveCategory === eMoveCategoryType.DoorToDoorExport) ||
      this.moveCategory === eMoveCategoryType.DoorToPort
    ) {
      if (
        this.moveTransport === eTransportType.Sea ||
        this.moveTransport === eTransportType.SeaLCL
      ) {
        this._portLabel = 'Sea';
      } else if (this.moveTransport === eTransportType.Air) {
        this._portLabel = 'Air';
      }
    }
  }
  createDictionary() {
    if (this._currentUserId !== undefined || null) {
      this._dictionary['User Id'] = this._currentUserId;
    }
    if (this.moveDetailId !== undefined || null) {
      this._dictionary['Move ID'] = this.generalService.formatId(
        this.moveDetailId,
        'move'
      );
    }
    if (this._moveDetail.trackingId !== undefined || null) {
      this._dictionary['Tracking ID'] = this._moveDetail.trackingId;
    }
    if (this._trackingUrl !== undefined || null) {
      this._dictionary['Tracking Url'] = this._trackingUrl;
    }
    if (this.clientName !== undefined || null) {
      this._dictionary['Client Name'] = this.clientName;
    }
    if (this.clientsCompany !== undefined || null) {
      this._dictionary['Client Company Name'] = this.clientsCompany;
    } else {
      this._dictionary['Client Company Name'] = '-';
    }

    // Optionally, log the merged dictionary
    // console.log('Merged Dictionary:', this._dictionary);

    // Return the merged dictionary or use it as needed in your application
    return this._dictionary;
  }
  getCurrentUrl(): Promise<string> {
    return new Promise((resolve, reject) => {
      const baseUrl = window.location.origin;
      const staticRoute = '/client-move';

      if (!baseUrl || !this._moveDetail.trackingId) {
        reject('Base URL or tracking ID not found');
      } else {
        this._trackingUrl = `${baseUrl}${staticRoute}?id=${this._moveDetail.trackingId}`;
        resolve(this._trackingUrl);
      }
      //console.log('url', this._trackingUrl);
    });
  }
}
