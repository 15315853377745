import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { PdfService } from 'src/services/pdf.service';
import { EnumMappingService } from '../../services/movd.enum.service';
import { MovdService } from '../../services/movd.service';
import { PackagingWeightCert } from '../../models/packaging-weight-cert.model';
import SignaturePad from 'signature_pad';
import { CompanySetting, getWeightUnitShortLabel, getVolumeUnitShortLabel, getCurrencySymbol } from '../../models/companysetting.model';
import { MoveTypeOptions, MoveCategoryTypeOptions, TransportTypeOptions } from '../../models/lead-detail.model';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { PackagingWeightCertItem } from '../../models/packaging-weight-cert-item.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from 'src/services/general.service';
import { PackingListResponse } from '../../survey-manager/packing-list/packing-board.model';

@Component({
  selector: 'app-weight-certificate',
  templateUrl: './weight-certificate.component.html',
  styleUrls: ['./weight-certificate.component.scss']
})
export class WeightCertificateComponent implements OnInit {
  @Input() activeTab: number = 2;
  @Input() packagingDetailId: any;
  @Input() weightCertificateTitle: any;
  @Input() leadId: any;  // for getting packaging details by leadid

  @ViewChild('customerSingatureCanvasWeight', { static: true }) customerSingatureCanvasWeight!: ElementRef<HTMLCanvasElement>;
  @ViewChild('surveyMgrSingatureCanvasWeight', { static: true }) surveyMgrSingatureCanvasWeight!: ElementRef<HTMLCanvasElement>;
  customerSignaturePadWeight!: SignaturePad;
  surveyMgrSignaturePadWeight!: SignaturePad;


  // variables
  _packagingWeightCertId: number | null = null;
  _externalNotesWeight!: string | null;

  // objects
  _packagingWeightCert = new PackagingWeightCert();
  weightCertItem = new PackagingWeightCertItem();

  // lists 
  weightCertsList: any[] = [];


  // export inventory list 
  @ViewChild('pdfContent') pdfContent!: any;
  _companySetting = new CompanySetting();
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _currencyUnit: string = '';
  _currency: any;
  _moveBoardItem: any;
  _moveBoardDTOList: any[] = [];

  _cd: any;
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;

  _packagingDetailDTO: any;
  pdfDocGenerator: any;

  _weightCertFile: any;
  modalRef: NgbModalRef | null = null;
  isLoading = false;
  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;

  constructor(
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    private _cdr: ChangeDetectorRef,
    public enumMappingService: EnumMappingService,
    private _pdfService: PdfService,
    private _sanitizer: DomSanitizer,
    private _modalService: NgbModal,
    private _authService: AuthService,
    private _http: HttpClient,
    public generalService: GeneralService
  ) {
    this._userRole = localStorage.getItem('role');

  }

  ngOnInit(): void {
    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Packing Form.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Packing Form.Create');
    this._updatePermission = permissions.includes('Packing Form.Update');
    this._deletePermission = permissions.includes('Packing Form.Delete');
    // this.addWeightCertItem();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['packagingDetailId']) {
      this.weightCertsList = [];
      this.packagingDetailId = changes['packagingDetailId'].currentValue;
      // console.log("if (changes['packagingDetailId'])", changes['packagingDetailId'].currentValue);
      this._packagingWeightCert.packagingDetailId = changes['packagingDetailId'].currentValue;
      // this._cdr.detectChanges();
      this.getCompanySetting();
      if (this.activeTab === 1) {
        // this.getPackagingInventoryByPackagingDetailId();
      } else if (this.activeTab === 2) {
        this.getPackagingWeightCertByPackagingDetailId();
      }
      this.getPackagingDetailsByLeadId();
    }

    if (changes['weightCertificateTitle']) {
      // console.log("if (changes['weightCertificateTitle'])");
      this.weightCertificateTitle = changes['weightCertificateTitle'].currentValue;
    }

    if (changes['leadId']) {
      this.leadId = changes['leadId'].currentValue;
      // console.log("if (changes['leadId'])", this.leadId);
    }
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();

    if (this.activeTab === 1) {
      // this.initializeInventorySignaturePads();
    } else if (this.activeTab === 2) {
      this.initializeWeightSignaturePads();
    }

  }


  initializeWeightSignaturePads() {
    // console.log("this.customerSingatureCanvasWeight", this.customerSingatureCanvasWeight);
    this.customerSignaturePadWeight = new SignaturePad(this.customerSingatureCanvasWeight.nativeElement);
    this.surveyMgrSignaturePadWeight = new SignaturePad(this.surveyMgrSingatureCanvasWeight.nativeElement);
  }

  getPackagingWeightCertByPackagingDetailId() {
    this._movdService.getPackagingWeightCertByPackagingDetailId(this.packagingDetailId).subscribe({
      next: (data: any) => {
        // console.log('getPackagingWeightCertByPackagingDetailId', data);
        if (data && data.length > 0) {
          this._packagingWeightCert = data[0];
          this._packagingWeightCertId = data[0].id;

          if (this._packagingWeightCert.externalNotes) {
            this._externalNotesWeight = this._packagingWeightCert.externalNotes;
          }
          if (this._packagingWeightCert.customerSignature) {
            this.renderCustomerSignature(this._packagingWeightCert.customerSignature, 'weight');
          }

          if (this._packagingWeightCert.managerSignature) {
            this.renderManagerSignature(this._packagingWeightCert.managerSignature, 'weight');
          }
          this.getPackagingWeightCertItemsByPackagingInventoryId();
        } else {
          this._packagingWeightCertId = 0;
          this._externalNotesWeight = null;
          this._packagingWeightCert = new PackagingWeightCert();
          this.initializeWeightSignaturePads();
        }
        //console.log('packaging weight id', this._packagingWeightCertId);
        //console.log('packaging weight cert data', this._packagingWeightCert);

      },
      error: (error: any) => {
        console.error("error getPackagingWeightCertByPackagingDetailId", error);
      }
    });
  }

  getPackagingWeightCertItemsByPackagingInventoryId() {
    this._packagingWeightCertId = this._packagingWeightCert.id;
    this._movdService.getPackagigWeightCertItemsByPackagingInventoryId(this._packagingWeightCertId).subscribe({
      next: (data: any) => {
        // console.log('Packaging weight cert items get', data);
        if (data) {
          this.weightCertsList = data;
          // if (this.weightCertsList.length === 0) {
          //    this.addWeightCertItem();
          // }
        }
        //console.log('filteredData', this._packagingWeightCert.id);
      },
      error: (error: any) => {
        console.error("error gettting packing weight cert items", error);
        if (error && error.status === 404 && this.weightCertsList.length === 0) {
          // this.addWeightCertItem();
        }
      }
    });
  }

  renderCustomerSignature(signatureDataURL: string, type: string) {
    const img = new Image();

    img.onload = () => {
      if (this.customerSignaturePadWeight) {
        this.customerSignaturePadWeight.clear();

        this.customerSignaturePadWeight.fromDataURL(signatureDataURL);
      } else {
        console.error('customerSignaturePadWeight is not defined');
      }
    };

    img.src = signatureDataURL;
  }

  renderManagerSignature(signatureDataURL: string, type: string) {
    const img = new Image();

    img.onload = () => {
      if (this.surveyMgrSignaturePadWeight) {
        this.surveyMgrSignaturePadWeight.clear();

        this.surveyMgrSignaturePadWeight.fromDataURL(signatureDataURL);
      } else {
        console.error('surveyMgrSignaturePadWeight is not defined');
      }
    };

    img.src = signatureDataURL;
  }

  addWeightCertItem() {
    const itemNo = this.weightCertsList.length + 1;

    this.weightCertsList.push({
      itemNo: itemNo,
      dimensions: '',
      gross: null,
      tare: null,
      net: null,
      volumeWeight: null,
      sealNo: null,
      isDeleted: false,
    });
    // console.log("addWeightCertItem", this.weightCertsList);
  }

  deleteWeightCertItem(rowIndex: number) {
    if (this.weightCertsList[rowIndex]) {
      // console.log("delete", this.weightCertsList[rowIndex])
      if (this.weightCertsList[rowIndex].id) {
        this.weightCertsList[rowIndex].isDeleted = true;
        this.saveWeightCertItems(rowIndex);
      }
      else {
        this.weightCertsList.splice(rowIndex, 1)
      }
    }
  }

  saveWeightSignature() {
    const custSignatureDataURL = this.customerSignaturePadWeight.toDataURL();
    const surveyMgrSignatureDataURL = this.surveyMgrSignaturePadWeight.toDataURL();

    this._packagingWeightCert.customerSignature = custSignatureDataURL;
    this._packagingWeightCert.managerSignature = surveyMgrSignatureDataURL;

    // //console.log("custSignatureDataURL url", custSignatureDataURL);
    // //console.log("surveyMgrSignatureDataURL url", surveyMgrSignatureDataURL);
  }

  savePackagingWeightCert() {
    //console.log('empty model', this._packagingWeightCert);
    this._packagingWeightCert.uploadedFile = this._weightCertFile;

    this._movdService.savePackagingWeightCert(this._packagingWeightCert).subscribe({
      next: (data: any) => {
        //console.log('Packaging Weight saved successfully:', data);
        this._appComponent.showSuccessSwal("saved", "record");
        this.getPackagingWeightCertByPackagingDetailId();
      },
      error: (error: any) => {
        // this._appComponent.showErrorSwal("saving", "record");
        console.error("error savePackagingWeightCert", error);
      }
    });
  }

  saveWeightCertItems(rowIndex: number) {
    const currentUserId = localStorage.getItem('currentUserId');
    const packagingWeightCertId = this._packagingWeightCertId;
    const item = this.weightCertsList[rowIndex];
    item.createdBy = Number(currentUserId);
    item.packagingWeightCertId = packagingWeightCertId;
    // console.log('before saving weight cert', item);

    if (!item.itemNo || !item.volumeWeight || !item.gross || !item.tare || !item.net || !item.sealNo) {
      this._appComponent.showErrorDialog('Error', 'Item details cannot be null / Zero');
      return;
    }

    //console.log('before weight certid', this._packagingWeightCertId);
    this._movdService.savePackagingWeightCertItem(item).subscribe({
      next: (data: any) => {
        this._appComponent.showSuccessSwal("Saved", "Item");
        this.getPackagingWeightCertItemsByPackagingInventoryId();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("Saving", "record");
        console.error("Error getting pack weigh certs", error);
        //console.log("Error", error);
      }
    });
  }

  clearSignature(user: string, type: string) {
    if (user === 'customer' && type === 'weight') {
      this.customerSignaturePadWeight.clear();
    } else if (user === 'manager' && type === 'weight') {
      this.surveyMgrSignaturePadWeight.clear();
    }
  }

  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }


  generatePDF() {
    const contentElement = this.pdfContent.nativeElement;

    if (contentElement) {
      contentElement.style.visibility = 'visible';
      contentElement.offsetHeight;

      // Generate the PDF
      this._pdfService.ConvertHTMLToPDF(contentElement, 'Inventory List');
      // Revert the visibility back to hidden
      contentElement.style.visibility = 'hidden';
    } else {
      console.error('HTML content not found');
    }
  }


  getPackagingDetailsByLeadId() {
    this._movdService.getPackagingDetailsByLeadId(this.leadId).subscribe({
      next: (data: PackingListResponse) => {
        // console.log("packaging details by leadId", data);
        if (data && data.packingList.length > 0) {
          const filteredData = data.packingList.find((item) => item.packagingDetailId === this.packagingDetailId);
          this._packagingDetailDTO = filteredData;
          // console.log('this._packagingDetailDTO = filteredData;', filteredData);
        }
      },
      error: (error) => {
        console.error("error getting packingdetail", error);
      }
    });
  }


  openWeightCertFileModal(content: TemplateRef<any>): void {
    this.modalRef = this._modalService.open(content, { size: 'lg' });

    this.modalRef.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }

  handleWeightCertFileInput(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this._weightCertFile = e.target.result;
        // console.log('inventory fileee', this._inventoryFile)
        if (file.type === 'application/pdf') {
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
        this.savePackagingWeightCert();
      };
      reader.readAsDataURL(file);
    }
  }

  async removeWeightCertFile() {
    let text: string = 'You are about to remove file.';
    let confirmButtonText: string = 'Yes, remove it!';
    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      this._weightCertFile = null;
      this.savePackagingWeightCert();
      if (this.modalRef) {
        this.modalRef.close();
      }
    }
    else {
      return;
    }
  }

  sanitizeDocumentUrl(imageUrl: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }

  createPdf() {
    return new Promise<void>((resolve, reject) => {
      // Include your docDefinition code here
      var docDefinition: any = {
        content: [
          this._companySetting.logo && this._companySetting.logo.startsWith('data:image') ? {
            image: this._companySetting.logo,
            width: 110,
            alignment: 'center',
          } : {
            text: 'Company Logo',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10],
          },
          {
            text: this.weightCertificateTitle,
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10] // [left, top, right, bottom]
          },
          {
            table: {
              layout: 'lightHorizontalLines',
              widths: ['50%', '50%'], // Two columns
              body: []
            },
            margin: [0, 10, 0, 20] // [left, top, right, bottom]
          }
        ],
        styles: {
          header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          columnText: {
            fontSize: 11,
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          smallHeadings: {
            bold: true,
            fontSize: 14,
            color: 'black'
          }
        },
        footer: function (currentPage: any, pageCount: any) {
          return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 10, alignment: 'right', margin: [0, 5, 40, 0] };
        }
      };

      // Your dynamic data for the second table
      var firstTableData = [];

      // Check conditions and add data accordingly
      // firstTableData.push(
      //   [{ text: 'Move Id:', style: 'columnText', alignment: 'left' },
      //   { text: '#' + this._packagingDetailDTO?.moveDetailId || ' - ', style: 'columnText', alignment: 'left' }]
      // );
      firstTableData.push(
        [{ text: 'Move Id:', style: 'columnText', alignment: 'left' },
        { text: (this._packagingDetailDTO?.moveDetailId ? this.generalService.formatId(this._packagingDetailDTO.moveDetailId, 'move') : '-'), style: 'columnText', alignment: 'left' }]
      );


      firstTableData.push(
        [{ text: 'Customer Id:', style: 'columnText', alignment: 'left' },
        { text: '#' + this._packagingDetailDTO?.customerId || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Customer Name:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.customerName || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Account Name:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.accountName || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Move Type:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveType(this._packagingDetailDTO?.moveType || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Move Category:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveCategoryType(this._packagingDetailDTO?.moveCategory || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Transport Type:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapTransportType(this._packagingDetailDTO?.moveTransport || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Origin:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.origin || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Destination:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.destination || ' - ', style: 'columnText', alignment: 'left' }]
      );

      // firstTableData.push(
      //   [{ text: 'POL:', style: 'columnText', alignment: 'left' },
      //   { text: this._packagingDetailDTO?.portOfLoading || ' - ', style: 'columnText', alignment: 'left' }]
      // );
      // firstTableData.push(
      //   [{ text: 'POD:', style: 'columnText', alignment: 'left' },
      //   { text: this._packagingDetailDTO?.portOfDischarge || ' - ', style: 'columnText', alignment: 'left' }]
      // );
      // firstTableData.push(
      //   [{ text: 'Place Of Delivery:', style: 'columnText', alignment: 'left' },
      //   { text: this._packagingDetailDTO?.placeOfDelivery || ' - ', style: 'columnText', alignment: 'left' }]
      // );
      firstTableData.push(
        [{ text: 'Packaging Date:', style: 'columnText', alignment: 'left' },
        { text: this.generalService.formatDate(this._packagingDetailDTO?.packagingDateFrom, this._packagingDetailDTO?.packagingDateTo) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Client Notes:', style: 'columnText', alignment: 'left' },
        { text: this._externalNotesWeight || ' - ', style: 'columnText', alignment: 'left' }]
      );

      if (docDefinition && docDefinition.content && docDefinition.content[2] && docDefinition.content[2].table && docDefinition.content[2].table.body) {
        // Add dynamic data to the second table
        firstTableData.forEach(data => {
          docDefinition.content[2].table.body.push(data);
        });
      }
      // console.log('first table data', firstTableData);

      // Inventory Items List

      // Weight Certificate Items Table
      if (this.weightCertsList.length > 0) {
        docDefinition.content.push(
          { text: this.weightCertificateTitle + '  ITEMS', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] }
        );

        var weightCertsListData = [];
        for (let row of this.weightCertsList) {
          if (row.isDeleted === false) {
            weightCertsListData.push([row.itemNo, row.dimensions, row.gross, row.tare, row.net, row.volumeWeight, row.sealNo]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['14%', '15%', '14%', '14%', '14%', '15%', '14%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Dimensions', style: 'tableHeader', alignment: 'left' },
                  { text: 'Gross Lbs ', style: 'tableHeader', alignment: 'left' },
                  { text: 'Tare Lbs ', style: 'tableHeader', alignment: 'left' },
                  { text: 'Net Lbs ', style: 'tableHeader', alignment: 'left' },
                  { text: 'Volume Weight', style: 'tableHeader', alignment: 'left' },
                  { text: 'Seal Number', style: 'tableHeader', alignment: 'left' },
                ],
                // Dynamic data goes here
                ...weightCertsListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          }
        );

        if (docDefinition && docDefinition.content && docDefinition.content[7] && docDefinition.content[7].table && docDefinition.content[7].table.body) {
          // Add dynamic data to the second table
          weightCertsListData.forEach(data => {
            docDefinition.content[7].table.body.push(data);
          });
        }
      }
      docDefinition.content.push({
        columns: [
          {
            stack: [
              {
                text: 'Customer Signature:', style: 'columnText', alignment: 'left',
                margin: [0, 30, 0, 0]
              },
              this._packagingWeightCert.customerSignature ? {
                image: this._packagingWeightCert.customerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0]
              } : null
            ]
          },
          {
            stack: [
              {
                text: 'Manager Signature:', style: 'columnText', alignment: 'right',
                margin: [0, 30, 0, 0]
              },
              this._packagingWeightCert.managerSignature ? {
                image: this._packagingWeightCert.managerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0],
                alignment: 'right'
              } : null
            ]
          }
        ]
      });
      // console.log('docDefinition:', docDefinition);

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      this.pdfDocGenerator = pdfDocGenerator;
      resolve();
    });
  }

  async viewPdf() {
    if (!this.pdfDocGenerator || this.pdfDocGenerator) {
      await this.createPdf();
    }
    this.pdfDocGenerator.open();
  }
  async downloadPdf() {
    if (!this.pdfDocGenerator || this.pdfDocGenerator) {
      await this.createPdf();
    }
    this.pdfDocGenerator.download();
  }
}
