import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Lead } from '../models/lead.model';
import { NgbDateStruct, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MovdService } from '../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import { SurveyMaterial } from '../models/survery-material.model';
import { MoveSurvey } from '../models/move-survery.model';
import { MoveCategoryTypeOptions, LeadDetail, MoveTypeOptions, TransportTypeOptions, eMoveCategoryType, eTransportType } from '../models/lead-detail.model';
import { EnumMappingService } from '../services/movd.enum.service';
import { PdfService } from '../../../../services/pdf.service';
import { CompanySetting, getWeightUnitShortLabel, getVolumeUnitShortLabel, getCurrencySymbol } from '../models/companysetting.model';
import { MoveAttachment } from '../models/move-attachments.model';
import { Address } from 'src/app/views/crm/contact/contact.models';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { LeadQuotation } from '../models/lead-quotation.model';
import { GeneralService } from 'src/services/general.service';
import { NavigationExtras, Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { LeadBoardDTO, LeadBoardResponse } from '../lead-board/lead-board.model';

@Component({
  selector: 'app-lead-summary',
  templateUrl: './lead-summary.component.html',
  styleUrls: ['./lead-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class LeadSummaryComponent {
  @Input() leadDetailId: any;

  @ViewChild('quotationModal', { static: false }) quotationModal: any;
  @ViewChild('#pdfSummary') pdfSummary: any;

  @ViewChild('pdfComponent') pdfContent!: any;
  @ViewChild('basicModal') basicModal: any;

  // objects
  pdfDocGenerator: any;
  _lead: Lead = new Lead();
  _leadDetail: LeadDetail = new LeadDetail();
  _moveSurvey: MoveSurvey = new MoveSurvey();
  _leadSummary = new LeadBoardDTO();

  // variables
  _basicModalRef: NgbModalRef | undefined;
  _isDataLoaded: boolean = false;
  quotationAmount: number = 0;
  selectedCurrencySymbol: string = ''; // Default currency symbol
  _totalWeight = 0;


  _loadingDate: NgbDateStruct | null = null;
  _loadingTime: string | null = null;
  _loadingDateTime: Date | null = null;

  // _moveDetailId: number = 0;

  // _surveyPlaces: [] = [];
  _surveyMaterialsList!: SurveyMaterial[];
  _areaItems: SurveyMaterial[] = [];
  _materials: SurveyMaterial[] = [];
  _labour: SurveyMaterial[] = [];
  _vehicles: SurveyMaterial[] = [];
  _containers: SurveyMaterial[] = [];
  _attachments: MoveAttachment[] = [];
  _filteredAreaItems: SurveyMaterial[] = [];
  _originAddresses: Address[] = [];
  _destinationAddresses: Address[] = [];

  _containerSizes: string[] = ['20\'', '40\'', '40\' HC'];

  // Initialize the map
  _surveyPlaceToItemMap: Map<string, string> = new Map();
  _selectedSurveyPlace: string | null = null;

  // enums
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;
  eMoveCategoryType = eMoveCategoryType;
  eTransportType = eTransportType;
  //
  selectedItem: any;
  selectedItems: { name: string; quantity: string; volume: string }[] = [];
  kgToLbsConversionFactor: number = 2.20462; // 1 kg = 2.20462 lbs
  cuftToCbmConversionFactor: number = 0.0283168; // 1 cuft = 0.0283168 cbm

  //company settings
  _companySetting = new CompanySetting();
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _currencyUnit: string = '';
  _currency: any;

  _leadQuotation = new LeadQuotation();

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;

  constructor(
    private _modalService: NgbModal,
    private _movdService: MovdService,
    public enumMappingService: EnumMappingService,
    private _pdfService: PdfService,
    public generalService: GeneralService,
    private _elementRef: ElementRef,
    private _router: Router,
    private _http: HttpClient,
    private _authService: AuthService
  ) {
    this._userRole = localStorage.getItem('role');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['leadDetailId']) {

      // Retrieve permissions from local storage
      const permissions = this._authService.getPermissionsFromLocalStorage();

      // Set permission variables
      this._readPermission = permissions.includes('Lead Info Form - Summary.Read') || this._userRole === 'CompanyAdmin';
      this._createPermission = permissions.includes('Lead Info Form - Summary.Create') || this._userRole === 'CompanyAdmin';
      this._updatePermission = permissions.includes('Lead Info Form - Summary.Update') || this._userRole === 'CompanyAdmin';
      this._deletePermission = permissions.includes('Lead Info Form - Summary.Delete');

      // console.log("leadDetailId", changes['leadDetailId']);
      this.leadDetailId = changes['leadDetailId'].currentValue;
      if (this.leadDetailId && this.leadDetailId !== 0) {
        this._areaItems = [];
        this._materials = [];
        this._labour = [];
        this._vehicles = [];
        this._containers = [];
        this._attachments = [];
        this._surveyMaterialsList = [];
        this._filteredAreaItems = [];
        this._totalWeight = 0;
        this.getLocationAndCurrency();
        this.getLeadSummary();
        this.getCompanySetting();
        this.getLeadQuotationById();
      }
    }
  }

  openQuotationModal() {
    this._modalService.open(this.quotationModal, { centered: true });
  }

  exportSummary() {
    const pdfSummaryElement = document.getElementById('pdfSummary'); // Replace 'pdfSummary' with the actual ID of your summary container
    // console.log("PDF Content", pdfSummaryElement);
    if (pdfSummaryElement) {
      // Use your PDF service to export the content
      this._pdfService.ConvertHTMLToPDF(pdfSummaryElement, 'Move-Summary-' + this.leadDetailId);
    } else {
      // console.log('Summary container not found');
    }
    // console.log("PDF Content", this.pdfSummary);
    //if (this.pdfSummary && this.pdfSummary.nativeElement) {
    //  const contentElement = this.pdfSummary.nativeElement;

    //  //const content = this.pdfContent.contentElement?.nativeElement;
    //  if (contentElement) {

    //    this._pdfService.ConvertHTMLToPDF(contentElement, 'Move-Summary-' + this.moveDetailId);
    //  } else {
    //    console.error('Div not found');
    //  }
    //}
  }

  getLeadSummary() {
    this._movdService.getLeadSummary(this.leadDetailId).subscribe({
      next: (response: LeadBoardResponse) => {
        this.getMoveSurveys();
        // this.getMoveDetail(); // not needed now as getting dates from move summary
        if (response && response.leads && response.leads.length > 0) {
          this._leadSummary = response.leads[0];
          // console.log("move summary", this._moveSummary);
        }
        // console.log("getLeadSummary", response);
      },
      error: (error: any) => {
        console.error("error", error);
      }
    });
  }

  saveQuotation() {
    // Send the quotation amount to the database or perform any necessary action
    // console.log('Quotation Amount:', this.quotationAmount);
    // Close the modal
    this._modalService.dismissAll();
    // Show a success message (you can use a toast or alert here)
    alert('Quotation saved successfully!');
  }

  getLocationAndCurrency() {

  }

  // total weight .. but as of now we have weight in string
  // get totalWeight(): number {
  //   return this._areaItems.reduce((total, item) => total + (item.weight || 0), 0);
  // }

  getMoveSurveys() {
    this._movdService.getMoveSurvey(this.leadDetailId).subscribe({
      next: (data: any) => {
        // console.log("move survey list", data);
        if (data) {
          this._moveSurvey = data;
          this._surveyMaterialsList = this._moveSurvey.surveyMaterials;

          this._surveyMaterialsList.forEach((material: SurveyMaterial) => {
            switch (material.type) {
              case 'Material':
                this._materials.push(material);
                break;
              case 'Area Item':
                this._areaItems.push(material);
                if (material.weight) {
                  this._totalWeight += material.weight;
                }
                break;
              case 'Labour':
                this._labour.push(material);
                break;
              case 'Vehicle':
                this._vehicles.push(material);
                break;
              case 'Container':
                this._containers.push(material);
                break;
              default:
                break;
            }
          });
          // this._isDataLoaded = true;

          if (this._moveSurvey.surveyAttachments && this._moveSurvey.surveyAttachments.length > 0) {
            this._attachments = this._moveSurvey.surveyAttachments;
          }
        }
        else {
          this._moveSurvey = new MoveSurvey();
        }
        // this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }



  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }

  calculateLoadDetails() {
    let loadDetails = this._containers.map(container => {
      if (container.item !== null) {
        let sizeName;
        switch (container.item) {
          case '20\'':
            sizeName = '20Ft';
            break;
          case '40\'':
            sizeName = '40Ft';
            break;
          case '40\' HC':
            sizeName = '40Ft HC';
            break;
          default:
            sizeName = container.item;
        }
        return `${sizeName}x${container.quantity}`;
      } else {
        return ''; // return an empty string when item is null
      }
    }).filter(item => item !== '').join(', '); // filter out any empty strings
    return loadDetails;
  }



  calculateVolume(surveyPlace: string, unit: string, type: string): string | number {
    let totalVolume;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalVolume = items.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    } else {
      totalVolume = this._areaItems.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalVolume.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'cuft' && this._volumeUnit === 'cuft') {
      // Convert from cuft to cbm
      const convertedVolume = totalVolume * this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cbm`;
    } else if (unit === 'cbm' && this._volumeUnit === 'cbm') {
      // Convert from cbm to cuft
      const convertedVolume = totalVolume / this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cuft`;
    }

    return result;
  }

  getItemCount(surveyPlace: string): number {
    const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  getTotalLabour(): number {
    return this._labour.reduce((total, item) => total + item.quantity, 0);
  }

  getTotalVehicles(): number {
    return this._vehicles.reduce((total, item) => total + item.quantity, 0);
  }

  calculateWeight(surveyPlace: string, unit: string, type: string): string | number {
    let totalWeight;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalWeight = items.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    } else {
      totalWeight = this._areaItems.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalWeight.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'kg' && this._weightUnit === 'kg') {
      // Convert from kg to lbs
      const convertedWeight = totalWeight * this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} lbs`;
    } else if (unit === 'lbs' && this._weightUnit === 'lbs') {
      // Convert from lbs to kg
      const convertedWeight = totalWeight / this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} kg`;
    }

    return result;
  }

  generatePDF() {
    const contentElement = this.pdfContent.nativeElement;

    if (contentElement) {
      contentElement.style.visibility = 'visible';
      contentElement.offsetHeight;

      // Generate the PDF
      this._pdfService.ConvertHTMLToPDF(contentElement, 'Move-Summary-' + this.leadDetailId);
      // Revert the visibility back to hidden
      contentElement.style.visibility = 'hidden';
    } else {
      console.error('HTML content not found');
    }
  }

  getDivContent(): string {
    const div = this._elementRef.nativeElement.querySelector('#pdf-content');
    return div.innerHTML;
  }
  openPdfModel() {
    const modalOptions: NgbModalOptions = {
      size: 'lg',
      centered: true,
    };

    this._basicModalRef = this._modalService.open(this.basicModal, modalOptions);
  }

  formatSurveyDate(date: NgbDateStruct): string {
    return date
      ? `${date.day.toString().padStart(2, '0')}/${date.month.toString().padStart(2, '0')}/${date.year}`
      : '';
  }

  formatDeliveryDate(date: NgbDateStruct) {
    return date
      ? `${date.day.toString().padStart(2, '0')}/${date.month.toString().padStart(2, '0')}/${date.year}`
      : '';
  }

  getLeadQuotationById() {
    this._movdService.getLeadQuotationsByLeadDetailId(this.leadDetailId).subscribe({
      next: (response: any) => {
        if (response) {
          this._leadQuotation = response;
          //console.log('lead quotation', this._leadQuotation);
        }
        else {
          this._leadQuotation = new LeadQuotation();
        }
      },
      error: (error: any) => {
        console.error("error getLeadQuotationsByLeadDetailId", error);
      }
    });
  }

  redirectToAccount(id: any) {
    if (!id) {
      return;
    }
    const text = 'company';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this._router.navigate(['/profile'], navigationExtras);
  }

  redirectToContact(id: any) {

    // console.log("account id", id);
    if (!id) {
      return;
    }
    const text = 'contact';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this._router.navigate(['/profile'], navigationExtras);
  }
  createPdf() {
    return new Promise<void>((resolve, reject) => {

      // Include your docDefinition code here
      var docDefinition: any = {
        content: [
          this._companySetting.logo && this._companySetting.logo.startsWith('data:image') ? {
            image: this._companySetting.logo,
            width: 110,
            alignment: 'center',
          } : {
            text: 'Company Logo',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10],
          },
          {
            text: 'Summary',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10] // [left, top, right, bottom]
          },
          {
            text: 'General Info',
            style: 'smallHeadings',
            alignment: 'left',
            margin: [0, 10, 0, 5] // [left, top, right, bottom]
          },

        ],
        styles: {
          header: {
            fontSize: 20,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          columnText: {
            fontSize: 11,
          },
          simpleText: {
            bold: true,
            fontSize: 12,
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          smallHeadings: {
            bold: true,
            fontSize: 14,
            color: 'black'
          }
        },
        footer: function (currentPage: any, pageCount: any) {
          return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 10, alignment: 'right', margin: [0, 5, 40, 0] };
        }
      };

      docDefinition.content.push(
        {
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        },
      );

      var firstTableData = [];

      // Check conditions and add data accordingly
      firstTableData.push(
        [{ text: 'Lead ID', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.formattedLeadDetailId || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Customer Id', style: 'columnText', alignment: 'left' },
        {
          text: this._leadSummary.formattedCustomerId || ' - ', style: 'columnText', alignment: 'left'
        }]
      );

      // firstTableData.push(
      //   [{ text: 'Customer Id', style: 'columnText', alignment: 'left' },
      //   { text: '#' + this._leadSummary?.customerId || ' - ', style: 'columnText', alignment: 'left' }]
      // );

      firstTableData.push(
        [{ text: 'Customer Name', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.customer || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Account Name', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.accountName || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Move Type', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.moveTypeLabel || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Move Category', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.moveCategoryLabel || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Transport Type', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.moveTransportLabel || ' - ', style: 'columnText', alignment: 'left' }]
      );
      if (this._leadDetail.categoryType == eMoveCategoryType.DoorToDoorExport || this._leadDetail.categoryType == eMoveCategoryType.DoorToPort && this._leadDetail.transportType == eTransportType.Sea || this._leadDetail.transportType == eTransportType.SeaLCL || this._leadDetail.transportType == eTransportType.Air) {

        firstTableData.push(
          [{ text: 'POL (Port of Loading)', style: 'columnText', alignment: 'left' },
          { text: this._leadSummary.portOfLoading || ' - ', style: 'columnText', alignment: 'left' }]
        );
        firstTableData.push(
          [{ text: 'POD (POrt of Discharge)', style: 'columnText', alignment: 'left' },
          { text: this._leadSummary.portOfDischarge || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }

      firstTableData.push(
        [{ text: 'Place Of Delivery', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.placeOfDelivery || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Requested Survey Date', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.formattedSurveyDate || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Requested Packing Date', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.packagingDate || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Requested Loading Date', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.loadingDate || ' - ', style: 'columnText', alignment: 'left' }]
      );
      if (this._leadSummary.storageDateFrom) {
        firstTableData.push(
          [{ text: 'Requested Storage Date', style: 'columnText', alignment: 'left' },
          { text: this._leadSummary.storageDate || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      firstTableData.push(
        [{ text: 'Client Notes', style: 'columnText', alignment: 'left' },
        { text: this._leadSummary.additionalNotes || ' - ', style: 'columnText', alignment: 'left' }]
      );

      if (docDefinition && docDefinition.content && docDefinition.content[3] && docDefinition.content[3].table && docDefinition.content[3].table.body) {
        // Add dynamic data to the second table
        firstTableData.forEach(data => {
          docDefinition.content[3].table.body.push(data);
        });
      }

      //Address Details
      docDefinition.content.push(
        { text: 'Address Details', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
      );

      for (let i = 0; i < Math.max(this._originAddresses.length, this._destinationAddresses.length); i++) {
        // Check if either origin or destination address exists at index i
        if (this._originAddresses[i] || this._destinationAddresses[i]) {
          // Add row for indicating the address index
          if (i !== 0) {
            docDefinition.content.push({
              text: `${i === 0 ? 'First' : i === 1 ? 'Second' : 'Third'} Address`,
              style: 'tableHeader',
              margin: [0, 10, 0, 6], // [left, top, right, bottom]
            });
          }

          // Add row for Origin and Destination headers
          docDefinition.content.push({
            table: {
              widths: ['25%', '25%', '25%', '25%'],
              body: [
                [{ text: 'Origin', style: 'tableHeader' }, '', { text: 'Destination', style: 'tableHeader' }, ''],
              ],
            },
            // Remove borders
          });

          // Add rows for street, city, state, country, and postal code
          const addressRows = [
            ['Street', this._originAddresses[i]?.streetAddress || '-', 'Street', this._destinationAddresses[i]?.streetAddress || '-'],
            ['City', this._originAddresses[i]?.city || '-', 'City', this._destinationAddresses[i]?.city || '-'],
            ['State', this._originAddresses[i]?.state || '-', 'State', this._destinationAddresses[i]?.state || '-'],
            ['Country', this._originAddresses[i]?.country || '-', 'Country', this._destinationAddresses[i]?.country || '-'],
            ['Postal Code', this._originAddresses[i]?.zipCode || '-', 'Postal Code', this._destinationAddresses[i]?.zipCode || '-'],
          ];

          addressRows.forEach(row => {
            docDefinition.content.push({
              table: {
                widths: ['25%', '25%', '25%', '25%'],
                body: [row],
              }, // Remove borders
            });
          });
        }
      }

      docDefinition.content.push(
        { text: 'Shipment Details', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
      );

      var shipmentData = [
        { text: this._leadSummary.moveTransportLabel || ' - ', style: 'columnText', alignment: 'left' },
        { text: this.calculateVolume('', this._volumeUnit, 'total') || ' - ', style: 'columnText', alignment: 'left' },
        { text: this.calculateWeight('', this._weightUnit, 'total') || ' - ', style: 'columnText', alignment: 'left' },
      ];


      docDefinition.content.push(
        {
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['33%', '33%', '34%'],
            body: [
              [
                { text: 'Shipment', style: 'tableHeader', alignment: 'left' },
                { text: 'Volume ' + '(' + (this._volumeUnit) + ')', style: 'tableHeader', alignment: 'left' },
                { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
              ],
              shipmentData
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        },
      );

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      this.pdfDocGenerator = pdfDocGenerator;
      resolve();
    });
  }

  async viewPdf() {
    if (!this.pdfDocGenerator || this.pdfDocGenerator) {
      await this.createPdf();
    }
    this.pdfDocGenerator.open();
  }
  async downloadPdf() {
    if (!this.pdfDocGenerator || this.pdfDocGenerator) {
      await this.createPdf();
    }
    this.pdfDocGenerator.download();
  }
}
